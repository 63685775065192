import jwtDecode from "jwt-decode";
import { Cookies } from "react-cookie";
import DateFormat from "dateformat";
/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
  const user = getLoggedInUser();

  if (!user) {
    return false;
  }
  const decoded = jwtDecode(user.token);
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    console.warn("access token expired");
    return false;
  } else {
    return true;
  }
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
  const cookies = new Cookies();
  const user = cookies.get("user");
  return user ? (typeof user == "object" ? user : JSON.parse(user)) : null;
};

const getRefreshDateTime = () => {
  // let refreshDateTime = "";
  // setInterval(() => {
  //   refreshDateTime = DateFormat(new Date(), "mmm dd, yyyy hh:MM tt");
  //   console.log("authutil refresh funciton called.....", refreshDateTime);
  // }, 60000);
  // return refreshDateTime ? refreshDateTime : null;
};

const getUserPicture = () => {
  const UserPicture = localStorage.getItem("UserPicture");
  return typeof UserPicture == "string" ? UserPicture : "";
};

const getUserMenus = () => {
  // const UserMenu = localStorage.getItem("UserMenu");
  // console.log("getUserMenus: ", typeof UserMenu);
  // return typeof UserMenu == "object" ? UserMenu : [];

  const cookies = new Cookies();
  const userMenu = cookies.get("UserMenu");
  if (userMenu) {
    //var result = Object.keys(userMenu).map(function (key) {
    //  return [userMenu[key].OptionLabel];
    //});
    return userMenu
  }

  return  null;
  //return result
  //  ? typeof result == "object"
  //    ? result
  //    : JSON.parse(result)
  //  : null;
};

export {
  isUserAuthenticated,
  getLoggedInUser,
  getUserPicture,
  getRefreshDateTime,
  getUserMenus,
};
