import React from "react";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";

import {
  isUserAuthenticated,
  getLoggedInUser,
  getUserMenus,
} from "./helpers/authUtils";

const NotAuthorized = React.lazy(() =>
  import("./components/breastTumor/notAuthorized.jsx")
);
// auth
const Login = React.lazy(() => import("./pages/auth/Login"));
const Logout = React.lazy(() => import("./pages/auth/Logout"));

//Covid'19 Components
const Home = React.lazy(() => import("./components/home/home.jsx"));
const OutBreakDashboard = React.lazy(() =>
  import("./components/coronaVirus/OutBreakDashboard.jsx")
);
const COVIDdDashboard = React.lazy(() =>
  import("./components/coronaVirus/covidDashboard.jsx")
);
const EHSCOVIDdDashboard = React.lazy(() =>
  import("./components/coronaVirus/ehsCovidDashboard.jsx")
);
const EHSCOVIDDasboardKenya = React.lazy(() =>
  import("./components/coronaVirus/ehsCovidDashboardKenya.jsx")
);
const AddCOVIDCase = React.lazy(() =>
  import("./components/coronaVirus/addCovidCase.jsx")
);
const AddEmpCOVIDCase = React.lazy(() =>
  import("./components/coronaVirus/addEmpCovidCase.jsx")
);
const Screening = React.lazy(() =>
  import("./components/coronaVirus/screening.jsx")
);
const Reporting = React.lazy(() =>
  import("./components/coronaVirus/reporting.jsx")
);
const ReportingTable = React.lazy(() =>
  import("./components/coronaVirus/reportingTable.jsx")
);
const ReportingLab = React.lazy(() =>
  import("./components/coronaVirus/reportingLab.jsx")
);
const ReportingTableLab = React.lazy(() =>
  import("./components/coronaVirus/reportingTableLab.jsx")
);
const CasesList = React.lazy(() =>
  import("./components/coronaVirus/totalCasesList.jsx")
);

/////////////////////////////////////////////////////////////
const PositiveAdmList = React.lazy(() =>
  import("./components/coronaVirus/positiveCasesList.jsx")
);
const NegativeAdmList = React.lazy(() =>
  import("./components/coronaVirus/negativeCasesList.jsx")
);
const PosToNegAdmList = React.lazy(() =>
  import("./components/coronaVirus/positiveToNegativeAdmittedList.jsx")
);
const SuspectedAdmList = React.lazy(() =>
  import("./components/coronaVirus/suspectedAdmittedList.jsx")
);
const EmergencyAdmList = React.lazy(() =>
  import("./components/coronaVirus/emergencyCasesList.jsx")
);

const PositiveDiscList = React.lazy(() =>
  import("./components/coronaVirus/positiveCasesDischarged.jsx")
);
const NegativeDiscList = React.lazy(() =>
  import("./components/coronaVirus/negativeDischargedList.jsx")
);
const PosToNegDiscList = React.lazy(() =>
  import("./components/coronaVirus/positiveToNegativeDischargedList.jsx")
);
const DepartedList = React.lazy(() =>
  import("./components/coronaVirus/departedCasesList.jsx")
);
const DeathList = React.lazy(() =>
  import("./components/coronaVirus/deathCasesList.jsx")
);

const ReceivedSpecList = React.lazy(() =>
  import("./components/coronaVirus/specimenSentList.jsx")
);
const PositiveSpecList = React.lazy(() =>
  import("./components/coronaVirus/specimenPositiveList.jsx")
);
const NegativeSpecList = React.lazy(() =>
  import("./components/coronaVirus/specimenNegativeList.jsx")
);
const ExtPositiveSpecList = React.lazy(() =>
  import("./components/coronaVirus/externalPositiveList.jsx")
);
const PendingSpecList = React.lazy(() =>
  import("./components/coronaVirus/specimenPendingList.jsx")
);

//////Students Dashboard Routes
const StudentPositiveAdmList = React.lazy(() =>
  import("./components/coronaVirus/stdPositiveCasesList")
);
const StudentNegativeAdmList = React.lazy(() =>
  import("./components/coronaVirus/stdNegativeCasesList")
);
const StudentPosToNegAdmList = React.lazy(() =>
  import("./components/coronaVirus/stdPositiveToNegativeAdmittedList.jsx")
);
const StudentSuspectedAdmList = React.lazy(() =>
  import("./components/coronaVirus/stdSuspectedAdmittedList.jsx")
);
const StudentEmergencyAdmList = React.lazy(() =>
  import("./components/coronaVirus/stdEmergencyCasesList.jsx")
);

const StudentPositiveDiscList = React.lazy(() =>
  import("./components/coronaVirus/stdPositiveCasesDischarged.jsx")
);
const StudentNegativeDiscList = React.lazy(() =>
  import("./components/coronaVirus/stdNegativeDischargedList.jsx")
);
const StudentPosToNegDiscList = React.lazy(() =>
  import("./components/coronaVirus/stdPositiveToNegativeDischargedList.jsx")
);
const StudentDepartedList = React.lazy(() =>
  import("./components/coronaVirus/stdDepartedCasesList.jsx")
);
const StudentDeathList = React.lazy(() =>
  import("./components/coronaVirus/stdDeathCasesList.jsx")
);
const StudentPositiveSpecList = React.lazy(() =>
  import("./components/coronaVirus/stdSpecimenPositiveList.jsx")
);
const StudentNegativeSpecList = React.lazy(() =>
  import("./components/coronaVirus/stdSpecimenNegativeList.jsx")
);
const StudentSpecimenActiveQuarantineList = React.lazy(() =>
  import("./components/coronaVirus/stdSpecimenActiveQuarantineList.jsx")
);
const StudentSpecimenRecoveredList = React.lazy(() =>
  import("./components/coronaVirus//stdSpecimenRecoveredList.jsx")
);
const StudentLabSuspected = React.lazy(() =>
  import("./components/coronaVirus/stdSpecimenSuspected.jsx")
);
const StudentLabExpected = React.lazy(() =>
  import("./components/coronaVirus/stdSpecimenExpected.jsx")
);

const StudentActiveScreening = React.lazy(() =>
  import("./components/coronaVirus/stdActiveScreening.jsx")
);
const StudentFitForCampus = React.lazy(() =>
  import("./components/coronaVirus/stdFitForCampus.jsx")
);
const StudentUnFitForWork = React.lazy(() =>
  import("./components/coronaVirus/stdUnFitForWork.jsx")
);

//////

const ScreeningPassFit = React.lazy(() =>
  import("./components/coronaVirus/screeningPassFit.jsx")
);
const ScreeningPassUnFit = React.lazy(() =>
  import("./components/coronaVirus/screeningPassUnfit.jsx")
);
const ScreeningPassExpired = React.lazy(() =>
  import("./components/coronaVirus/screeningPassExpired.jsx")
);

///Student Passes
const StdScreeningPassFit = React.lazy(() =>
  import("./components/coronaVirus/stdScreeningPassFit.jsx")
);
const StdScreeningPassUnFit = React.lazy(() =>
  import("./components/coronaVirus/stdScreeningPassUnfit.jsx")
);
///
////////Outbreak Routes
const OutbreakDetails = React.lazy(() =>
  import("./components/coronaVirus/OutbreakDetails")
);

////////Employee Dashboard Routes
const EmployeePositiveAdmList = React.lazy(() =>
  import("./components/coronaVirus/empPositiveCasesList")
);
const EmployeeNegativeAdmList = React.lazy(() =>
  import("./components/coronaVirus/empNegativeCasesList")
);
const EmployeePosToNegAdmList = React.lazy(() =>
  import("./components/coronaVirus/empPositiveToNegativeAdmittedList.jsx")
);
const EmployeeSuspectedAdmList = React.lazy(() =>
  import("./components/coronaVirus/empSuspectedAdmittedList.jsx")
);

const EmployeeDependentAdmList = React.lazy(() =>
  import("./components/coronaVirus/empDependentAdmittedList.jsx")
);
const EmployeeEmergencyAdmList = React.lazy(() =>
  import("./components/coronaVirus/empEmergencyCasesList.jsx")
);

const EmployeePositiveDiscList = React.lazy(() =>
  import("./components/coronaVirus/empPositiveCasesDischarged.jsx")
);
const EmployeeNegativeDiscList = React.lazy(() =>
  import("./components/coronaVirus/empNegativeDischargedList.jsx")
);
const EmployeePosToNegDiscList = React.lazy(() =>
  import("./components/coronaVirus/empPositiveToNegativeDischargedList.jsx")
);
const EmployeeDepartedList = React.lazy(() =>
  import("./components/coronaVirus/empDepartedCasesList.jsx")
);
const EmployeeDeathList = React.lazy(() =>
  import("./components/coronaVirus/empDeathCasesList.jsx")
);
const EmployeePositiveSpecList = React.lazy(() =>
  import("./components/coronaVirus/empSpecimenPositiveList.jsx")
);
const EmployeeNegativeSpecList = React.lazy(() =>
  import("./components/coronaVirus/empSpecimenNegativeList.jsx")
);
const EmployeeSpecimenActiveQuarantineList = React.lazy(() =>
  import("./components/coronaVirus/empSpecimenActiveQuarantineList.jsx")
);
const EmployeeSpecimenRecoveredList = React.lazy(() =>
  import("./components/coronaVirus//empSpecimenRecoveredList.jsx")
);
const EmployeeLabSuspected = React.lazy(() =>
  import("./components/coronaVirus/empSpecimenSuspected.jsx")
);
const EmployeeLabExpected = React.lazy(() =>
  import("./components/coronaVirus/empSpecimenExpected.jsx")
);

const EmployeeActiveScreening = React.lazy(() =>
  import("./components/coronaVirus/empActiveScreening.jsx")
);
const EmployeeFitForCampus = React.lazy(() =>
  import("./components/coronaVirus/empFitForCampus.jsx")
);
const EmployeeUnFitForWork = React.lazy(() =>
  import("./components/coronaVirus/empUnFitForWork.jsx")
);

const EmployeeActiveScreeningKenya = React.lazy(() =>
  import("./components/coronaVirus/empActiveScreeningKenya.jsx")
);
const EmployeeFitForCampusKenya = React.lazy(() =>
  import("./components/coronaVirus/empFitForCampusKenya.jsx")
);
const EmployeeUnFitForWorkKenya = React.lazy(() =>
  import("./components/coronaVirus/empUnFitForWorkKenya.jsx")
);
const SHSCOVIDdDashboard = React.lazy(() =>
  import("./components/coronaVirus/shsCovidDashboard.jsx")
);
const AddRESEmpCovidCase = React.lazy(() =>
  import("./components/coronaVirus/addRESEmpCovidCase.jsx")
);

// handle auth and authorization

const PrivateRoute = ({
  component: Component,
  roles,
  name,
  menuMap,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      const { path, formAction, reportingOption } = { ...rest };
      const loggedInUser = getLoggedInUser();
      const isAuthTokenValid = isUserAuthenticated();

      console.log("path: ", path);
      console.log("menuMap: ", menuMap);
      console.log("roles: ", roles);
      console.log("loggedInUser.Role: ", getUserMenus());

      if (!isAuthTokenValid) {
        // not logged in so redirect to login page with the return url
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }

      // check if route is restricted by role
      // if (roles && roles.indexOf(loggedInUser.Role) === -1) {
      //   // role not authorised so redirect to home page
      //   return <Redirect to={{ pathname: "/notAuthorized" }} />;
      // }

      if (menuMap) {
        let userMenus = getUserMenus();
        let _menu = userMenus.find((a) => menuMap.includes(a[0]));
        if (!_menu) {
          return <Redirect to={{ pathname: "/notAuthorized" }} />;
        }
      }
      let reportingData = {};
      if (props.history.location.location) {
        let { state } = props.history.location.location;
        reportingData = state;
      }

      let model = {};
      let formData = {};

      if (props.location.state) {
        formData = props.location.state.data;
      }

      props.location.state = formAction
        ? { data: formData ? formData : model, formAction }
        : props.location.state;

      props.location.state = reportingOption
        ? { reportingData, reportingOption }
        : props.location.state;

      // authorised so return component
      return <Component roles={roles} {...props} />;
    }}
  />
);
let getUserMenu = getUserMenus();
getUserMenu = getUserMenu ? getUserMenu : null;
// length = getUserMenu ? getUserMenu.length : null;

const routes = [
  // auth and account
  { path: "/login", name: "Login", component: Login, route: Route },
  { path: "/logout", name: "Logout", component: Logout, route: Route },
  {
    path: "/",
    exact: true,
    name: "Home",
    component: Home,
    route: PrivateRoute,
  },
  //Custom
  {
    path: "/notAuthorized",
    name: "Not Authorized",
    component: NotAuthorized,
    route: PrivateRoute,
  },

  //Covid'19 Redirects
  {
    path: "/home",
    name: "Home",
    component: Home,
    route: PrivateRoute,
  },
  {
    path: "/OutBreakDashboard",
    name: "OutBreak Dashboard",
    component: OutBreakDashboard,
    menuMap: "mnuOutBreakDashboard",
    route: PrivateRoute,
    // roles: [
    //   "ADMIN",
    //   "DASH",
    //   "OUTBREAKDASH",
    //   "KEHSDASHBOARD"
    // ]
  },
  {
    path: "/covidDashboard",
    name: "COVID Dashboard",
    component: COVIDdDashboard,
    menuMap: "mnuPUIDashboard",
    route: PrivateRoute,
    // roles: [
    //   "ADMIN",
    //   "OUTBREAKDASH",
    //   "MGMNTDASHBOARD",
    //   "PUIPORTING",
    //   "PUIDASHBOARD",
    //   "DASH",
    //   "PUIEHSDEPTDASH",
    // ],
  },
  {
    path: "/ehsCovidDashboard",
    name: "EHS COVID Dashboard",
    component: EHSCOVIDdDashboard,
    menuMap: "mnuEHSDashboard",
    route: PrivateRoute,
    // roles: [
    //   "ADMIN",
    //   "OUTBREAKDASH",
    //   "EHSDASHBOARD",
    //   "MGMNTDASHBOARD",
    //   "DASH",
    //   "EHSSCRNDASH",
    //   "EHSRESDASHBOARD",
    //   "EHSSCRRESDASH",
    //   "EHSDEPSCRESDASH",
    //   "EHSDEPRESDASH",
    //   "EHSPGMRESDASH",
    //   "EHSPGMSCRESDASH",
    //   "PUIEHSDEPTDASH",
    // ],
  },
  {
    path: "/shsCovidDashboard",
    name: "SHS COVID Dashboard",
    component: SHSCOVIDdDashboard,
    menuMap: "mnuSTSDashboard",
    route: PrivateRoute,
    // roles: [
    //   "ADMIN",
    //   "OUTBREAKDASH",
    //   "STSDASHBOARD",
    //   "EHSSTDSCRDASHA",
    //   "EHSSTDSCRDASHMH",
    //   "EHSSTDSCRDASHFH"
    // ],
  },
  {
    path: "/ehsCovidDashboardKenya",
    name: "EHS COVID Dashboard",
    component: EHSCOVIDDasboardKenya,
    menuMap: "mnuKEHSDashboard",
    route: PrivateRoute,
    // roles: ["ADMIN", "OUTBREAKDASH", "KEHSDASHBOARD"],
  },
  {
    path: "/addCase",
    name: "Add COVID'19 Case",
    formAction: "AddForm",
    component: AddCOVIDCase,
    menuMap: "mnuPUIScreening",
    route: PrivateRoute,
    // roles: [
    //   "ADMIN",
    //   "OUTBREAKDASH",
    //   "PUISCRNNING",
    //   "PUIPORTING",
    //   "EHSSCRRESDASH",
    //   "EHSANDPUIFORM",
    //   //  "PUIEHSDEPTDASH",
    // ],
  },
  {
    path: "/addEmployeeCase",
    name: "Add Employee COVID'19 Case",
    formAction: "AddForm",
    component: AddEmpCOVIDCase,
    menuMap: ["mnuEHSScreening", "mnuRESScreening"],
    route: PrivateRoute,
    // roles: [
    //   "ADMIN",
    //   "OUTBREAKDASH",
    //   "EHSSCREENING",
    //   "EHSSCRNDASH",
    //   "EHSSCRRESDASH",
    //   "EHSANDPUIFORM",
    //   "KEHSDASHBOARD",
    //   "EHSDEPSCRESDASH",
    //   "EHSPGMSCRESDASH",
    //   "PUIEHSDEPTDASH",
    //   "STSDASHBOARD",
    //   "EHSSTDSCRDASHA",
    //   "EHSSTDSCRDASHMH",
    //   "EHSSTDSCRDASHFH"
    // ],
  },

  //RESScreeing
  {
    path: "/addRESEmployeeCase",
    name: "Resctricted Screening",
    formAction: "AddForm",
    component: AddRESEmpCovidCase,
    menuMap: "mnuRESScreening",
    route: PrivateRoute,
  },

  {
    path: "/labReporting",
    name: "LAB Reporting",
    component: ReportingLab,
    reportingOption: "LABReporting",
    menuMap: "mnuLABReporting",
    route: PrivateRoute,
    // roles: ["ADMIN", "OUTBREAKDASH","LABREPORTING"],
  },
  {
    path: "/dipheReporting",
    name: "DIPHE Reporting",
    component: Reporting,
    reportingOption: "DIPHEReporting",
    menuMap: "mnuDIPHEReporting",
    route: PrivateRoute,
    // roles: ["ADMIN", "OUTBREAKDASH","PUIPORTING", "PUIEHSDEPTDASH"],
  },
  {
    path: "/reportingtableLab",
    name: "Reporting Table Lab",
    component: ReportingTableLab,
    route: PrivateRoute,
    // roles: ["ADMIN", "OUTBREAKDASH","LABREPORTING", "PUIPORTING"],
  },
  {
    path: "/reportingtable",
    name: "Reporting Table",
    component: ReportingTable,
    route: PrivateRoute,
    // roles: ["ADMIN", "OUTBREAKDASH","LABREPORTING", "PUIPORTING"],
  },
  {
    path: "/casesList",
    name: "Cases List",
    component: CasesList,
    route: PrivateRoute,
    // roles: ["ADMIN", "OUTBREAKDASH","PUISCRNNING", "PUIPORTING", "EHSANDPUIFORM"],
  },
  {
    path: "/editCase",
    name: "Edit COVID'19 Case",
    component: AddCOVIDCase,
    formAction: "EditForm",
    route: PrivateRoute,
    // roles: ["ADMIN", "OUTBREAKDASH", "PUISCRNNING", "PUIPORTING", "EHSANDPUIFORM"],
  },
  {
    path: "/viewCase",
    name: "View COVID'19 Case",
    component: AddCOVIDCase,
    formAction: "ViewForm",
    route: PrivateRoute,
    // roles: ["ADMIN", "OUTBREAKDASH", "PUISCRNNING", "PUIPORTING", "EHSANDPUIFORM"],
  },
  {
    path: "/screening",
    name: "Screening",
    component: Screening,
    route: PrivateRoute,
    // roles: [
    //   "ADMIN",
    //   "OUTBREAKDASH",
    //   "PUISCRNNING",
    //   "PUIPORTING",
    //   "EHSANDPUIFORM",
    //   "KEHSDASHBOARD",
    //   "EHSDEPSCRESDASH",
    //   "PUIEHSDEPTDASH",
    //   "STSDASHBOARD"
    // ],
  },

  ///////////////////Child Routes Defined Below//////////////////////////
  {
    path: "/positiveadmitted",
    name: "Positive Cases List",
    component: PositiveAdmList,
    route: PrivateRoute,
  },
  {
    path: "/negativeadmitted",
    name: "Negative Cases List",
    component: NegativeAdmList,
    route: PrivateRoute,
  },
  {
    path: "/positivetonegativeadmitted",
    name: "Positive Cases List",
    component: PosToNegAdmList,
    route: PrivateRoute,
  },
  {
    path: "/suspectedadmitted",
    name: "Negative Cases List",
    component: SuspectedAdmList,
    route: PrivateRoute,
  },
  {
    path: "/emergencyadmitted",
    name: "Negative Cases List",
    component: EmergencyAdmList,
    route: PrivateRoute,
  },

  {
    path: "/positivedischarged",
    name: "Positive Cases List",
    component: PositiveDiscList,
    route: PrivateRoute,
  },
  {
    path: "/negativedischarged",
    name: "Negative Cases List",
    component: NegativeDiscList,
    route: PrivateRoute,
  },
  {
    path: "/positivetonegativedischarged",
    name: "Positive Cases List",
    component: PosToNegDiscList,
    route: PrivateRoute,
  },
  {
    path: "/departed",
    name: "Negative Cases List",
    component: DepartedList,
    route: PrivateRoute,
  },
  {
    path: "/death",
    name: "Negative Cases List",
    component: DeathList,
    route: PrivateRoute,
  },

  {
    path: "/receivedspecimen",
    name: "Positive Cases List",
    component: ReceivedSpecList,
    route: PrivateRoute,
  },
  {
    path: "/positivespecimen",
    name: "Negative Cases List",
    component: PositiveSpecList,
    route: PrivateRoute,
  },
  {
    path: "/negativespecimen",
    name: "Positive Cases List",
    component: NegativeSpecList,
    route: PrivateRoute,
  },
  {
    path: "/externalpositive",
    name: "Negative Cases List",
    component: ExtPositiveSpecList,
    route: PrivateRoute,
  },
  {
    path: "/pendingspecimen",
    name: "Negative Cases List",
    component: PendingSpecList,
    route: PrivateRoute,
  },
  {
    path: "/passfit",
    name: "Pass Fit",
    component: ScreeningPassFit,
    route: PrivateRoute,
  },
  {
    path: "/passunfit",
    name: "Pass UnFit",
    component: ScreeningPassUnFit,
    route: PrivateRoute,
  },
  {
    path: "/screeningexpired",
    name: "Pass Expired",
    component: ScreeningPassExpired,
    route: PrivateRoute,
  },
  //Student Screening Passes Route
  {
    path: "/stdpassfit",
    name: "Pass Fit",
    component: StdScreeningPassFit,
    route: PrivateRoute,
  },
  {
    path: "/stdpassunfit",
    name: "Pass UnFit",
    component: StdScreeningPassUnFit,
    route: PrivateRoute,
  },
  /////

  //////Outbreak
  {
    path: "/OutbreakDetails",
    name: "OutbreakDetails",
    component: OutbreakDetails,
    route: PrivateRoute,
  },

  //////EMPDashboardRoute
  {
    path: "/emppositiveadmitted",
    name: "Employee Positive Cases List",
    component: EmployeePositiveAdmList,
    route: PrivateRoute,
  },
  {
    path: "/empnegativeadmitted",
    name: "Employee Negative Cases List",
    component: EmployeeNegativeAdmList,
    route: PrivateRoute,
  },
  {
    path: "/emppositivetonegativeadmitted",
    name: "Employee Positive Cases List",
    component: EmployeePosToNegAdmList,
    route: PrivateRoute,
  },
  {
    path: "/empsuspectedadmitted",
    name: "Employee Negative Cases List",
    component: EmployeeSuspectedAdmList,
    route: PrivateRoute,
  },
  {
    path: "/empdependentadmitted",
    name: "Employee's Dependent Cases List",
    component: EmployeeDependentAdmList,
    route: PrivateRoute,
  },
  {
    path: "/empemergencyadmitted",
    name: "Employee Negative Cases List",
    component: EmployeeEmergencyAdmList,
    route: PrivateRoute,
  },

  {
    path: "/emppositivedischarged",
    name: "Employee Positive Cases List",
    component: EmployeePositiveDiscList,
    route: PrivateRoute,
  },
  {
    path: "/empnegativedischarged",
    name: "Employee Negative Cases List",
    component: EmployeeNegativeDiscList,
    route: PrivateRoute,
  },
  {
    path: "/emppositivetonegativedischarged",
    name: "Employee Positive Cases List",
    component: EmployeePosToNegDiscList,
    route: PrivateRoute,
  },
  {
    path: "/empdeparted",
    name: "Employee Negative Cases List",
    component: EmployeeDepartedList,
    route: PrivateRoute,
  },
  {
    path: "/empdeath",
    name: "Employee Negative Cases List",
    component: EmployeeDeathList,
    route: PrivateRoute,
  },
  {
    path: "/empactivequarantine",
    name: "Employee Negative Cases List",
    component: EmployeeSpecimenActiveQuarantineList,
    route: PrivateRoute,
  },
  {
    path: "/empspecimenrecovered",
    name: "Employee Specimen Recovered List",
    component: EmployeeSpecimenRecoveredList,
    route: PrivateRoute,
  },

  {
    path: "/emppositivespecimen",
    name: "Employee Positive Cases List",
    component: EmployeePositiveSpecList,
    route: PrivateRoute,
  },
  {
    path: "/empnegativespecimen",
    name: "Employee Negative Cases List",
    component: EmployeeNegativeSpecList,
    route: PrivateRoute,
  },
  {
    path: "/emplabsuspected",
    name: "Employee Negative Cases List",
    component: EmployeeLabSuspected,
    route: PrivateRoute,
  },
  {
    path: "/emplabexpected",
    name: "Employee Negative Cases List",
    component: EmployeeLabExpected,
    route: PrivateRoute,
  },

  {
    path: "/empactivescreening",
    name: "Employee Negative Cases List",
    component: EmployeeActiveScreening,
    route: PrivateRoute,
  },
  {
    path: "/empfitforcampus",
    name: "Employee Negative Cases List",
    component: EmployeeFitForCampus,
    route: PrivateRoute,
  },
  {
    path: "/empunfitforwork",
    name: "Employee Negative Cases List",
    component: EmployeeUnFitForWork,
    route: PrivateRoute,
  },
  //////KENYA DASHBOARD ROUTES
  {
    path: "/empactivescreeningkenya",
    name: "Employee Negative Cases List",
    component: EmployeeActiveScreeningKenya,
    route: PrivateRoute,
  },
  {
    path: "/empfitforcampuskenya",
    name: "Employee Negative Cases List",
    component: EmployeeFitForCampusKenya,
    route: PrivateRoute,
  },
  {
    path: "/empunfitforworkkenya",
    name: "Employee Negative Cases List",
    component: EmployeeUnFitForWorkKenya,
    route: PrivateRoute,
  },
  /////////END

  ////Student Dashboard Routes
  {
    path: "/stdpositiveadmitted",
    name: "Student Positive Cases List",
    component: StudentPositiveAdmList,
    route: PrivateRoute,
  },
  {
    path: "/stdnegativeadmitted",
    name: "Student Negative Cases List",
    component: StudentNegativeAdmList,
    route: PrivateRoute,
  },
  {
    path: "/stdpositivetonegativeadmitted",
    name: "Student Positive Cases List",
    component: StudentPosToNegAdmList,
    route: PrivateRoute,
  },
  {
    path: "/stdsuspectedadmitted",
    name: "Student Negative Cases List",
    component: StudentSuspectedAdmList,
    route: PrivateRoute,
  },
  {
    path: "/stdemergencyadmitted",
    name: "Student Negative Cases List",
    component: StudentEmergencyAdmList,
    route: PrivateRoute,
  },

  {
    path: "/stdpositivedischarged",
    name: "Student Positive Cases List",
    component: StudentPositiveDiscList,
    route: PrivateRoute,
  },
  {
    path: "/stdnegativedischarged",
    name: "Student Negative Cases List",
    component: StudentNegativeDiscList,
    route: PrivateRoute,
  },
  {
    path: "/stdpositivetonegativedischarged",
    name: "Student Positive Cases List",
    component: StudentPosToNegDiscList,
    route: PrivateRoute,
  },
  {
    path: "/stddeparted",
    name: "Student Negative Cases List",
    component: StudentDepartedList,
    route: PrivateRoute,
  },
  {
    path: "/stddeath",
    name: "Student Negative Cases List",
    component: StudentDeathList,
    route: PrivateRoute,
  },
  {
    path: "/stdactivequarantine",
    name: "Student Negative Cases List",
    component: StudentSpecimenActiveQuarantineList,
    route: PrivateRoute,
  },
  {
    path: "/stdspecimenrecovered",
    name: "Student Specimen Recovered List",
    component: StudentSpecimenRecoveredList,
    route: PrivateRoute,
  },

  {
    path: "/stdpositivespecimen",
    name: "Student Positive Cases List",
    component: StudentPositiveSpecList,
    route: PrivateRoute,
  },
  {
    path: "/stdnegativespecimen",
    name: "Student Negative Cases List",
    component: StudentNegativeSpecList,
    route: PrivateRoute,
  },
  {
    path: "/stdlabsuspected",
    name: "Student Negative Cases List",
    component: StudentLabSuspected,
    route: PrivateRoute,
  },
  {
    path: "/stdlabexpected",
    name: "Student Negative Cases List",
    component: StudentLabExpected,
    route: PrivateRoute,
  },

  {
    path: "/stdactivescreening",
    name: "Student Negative Cases List",
    component: StudentActiveScreening,
    route: PrivateRoute,
  },
  {
    path: "/stdfitforcampus",
    name: "Student Negative Cases List",
    component: StudentFitForCampus,
    route: PrivateRoute,
  },
  {
    path: "/stdunfitforwork",
    name: "Student Negative Cases List",
    component: StudentUnFitForWork,
    route: PrivateRoute,
  },

  /////End
];

export { routes, PrivateRoute };
