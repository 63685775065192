const baseURL = "https://idrsapi.aku.edu/api";
//const baseURL = "http://localhost:3315/api";
const ServiceToken =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJBY2Nlc3NEYXRlVGltZSI6NjM2ODYyNjYwNzMyMzIxMTU4LCJBcHBsaWNhdGlvbklEIjoiT05FVElNRVRPS0VOIiwiVG9rZW5OYXR1cmUiOiJOIiwiVG9rZW5OZWVkIjoiT25lVGltZVRva2VuQ2FsbCIsIlRva2VuS2V5IjoiMXFhejNlZGM1dGhuN3VqbTJ3ZGM0cmdiMGlodjc4Zng1ZXN6In0.vMtFFSEtO_CGIe5_y_jsCqcOk1SjAz7E8YY6ESbVadY";

const baseURLEHS = "https://ehsliveapi.aku.edu/api";
//const baseURLEHS = "";
const ServiceTokenEHS =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJKV1RTZWNyZXRLZXkiOiJHUURzdGNLc3gwTkhqUE91WE9ZZzVNYmVKMVhUMHVGaXdEVnZWQnJrTW5NIiwiQVBQIjoiRUhTIn0.FnFfCEGEN1o-Oj71a1_t8phbV5hzL19FqURXQ2R8VUU";

export let FetchData = (RequestMethod, RequestData, Type = "") => {
  var reqBody = new FormData();
  reqBody.append("RequestMethod", RequestMethod);

  if (Type == "string") {
    reqBody.append("RequestData", RequestData);
  } else {
    reqBody.append("RequestData", JSON.stringify(RequestData));
  }
  // return $.ajax({
  //   type: "POST",
  //   beforeSend: function(request) {
  //     request.setRequestHeader("_token", ServiceToken);
  //   },
  //   url: baseURL,
  //   data: reqBody,
  //   processData: false,
  //   contentType: false
  // });

  const options = {
    body: reqBody,
    method: "POST",
    headers: {
      _token: ServiceToken,
    },
  };

  return fetch(baseURL, options)
    .then((response) => {
      if (!response.status === 200) {
        throw response.json();
      }
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      throw error;
    });
};

export let FetchDataEHS = (RequestMethod, RequestData, Type = "") => {
  var reqBody = new FormData();
  reqBody.append("RequestMethod", RequestMethod);

  if (Type == "string") {
    reqBody.append("RequestData", RequestData);
  } else {
    reqBody.append("RequestData", JSON.stringify(RequestData));
  }

  const options = {
    body: reqBody,
    method: "POST",
    headers: {
      _token: ServiceTokenEHS,
    },
  };

  return fetch(baseURLEHS, options)
    .then((response) => {
      if (!response.status === 200) {
        throw response.json();
      }
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      throw error;
    });
};
