// @flow
import { Cookies } from "react-cookie";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { FetchData } from "../../services/baseService";

import {
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  FORGET_PASSWORD,
} from "../../constants/actionTypes";

import {
  loginUserSuccess,
  loginUserFailed,
  registerUserSuccess,
  registerUserFailed,
  forgetPasswordSuccess,
  forgetPasswordFailed,
} from "./actions";

/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchJSON = (url, options = {}) => {
  //   url =
  //     "http://userauthserviceuat.aku.edu/api/AuthenticateUser?UserName=abdulmujeeb.qureshi&Password=123Mujeeb23f&Application=HHFWEB&WebUrl=localhost";
  // console.log("url: ", url);
  // console.log("options: ", options);
  //return fetch(url, options)
  return fetch(url, options)
    .then((response) => {
      if (!response.status === 200) {
        throw response.json();
      }
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log("response error: ", error);
      throw error;
    });
};

/**
 * Sets the session
 * @param {*} user
 */
const setSession = (user) => {
  let cookies = new Cookies();
  if (user) 
  {
    user.menuList = [];
    cookies.set("user", JSON.stringify(user), { path: "/" });
  }
  else{
    cookies.remove("user");
  } 
};

const setUserPicture = (UserPicture) => {
  localStorage.setItem("UserPicture", UserPicture);
};
const setUserMenus = (UserMenu) => {
  console.log("setUserMenus: ", UserMenu);
  console.log("setUserMenus type: ", typeof UserMenu);
  //localStorage.setItem("UserMenu", UserMenu);

  let cookies = new Cookies();
  if (UserMenu) 
  {
    let _usermenus = UserMenu.map((menu,i)=>[menu.OptionLabel])
    console.log("_usermenus",_usermenus)
    cookies.remove("UserMenu");
    cookies.set("UserMenu", _usermenus, { path: "/" });
  }
  else{
    cookies.remove("UserMenu");
  } 
};
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password } }) {
  //const options = {
  // body: JSON.stringify({
  //   UserName: username,
  //   Password: password,
  //   Application: "HHFWEB",
  //   WebUrl: "localhost"
  // }),
  // body: JSON.stringify({ username, password }),
  // method: "POST",
  // headers: { "Content-Type": "application/json" }
  //};

  try {
    const response = yield call(FetchData, "CovidManager.SignIn", {
      UserID: username,
      Password: password,
    });

    const { ResponseResult, ResponseMessage } = response;
    console.log("response: ", response);
    if (ResponseResult != null && ResponseResult.UserID != null) {
      let responseMod = {
        LoginStatus: ResponseResult.LoginStatus,
        Mnemonic: ResponseResult.Mnemonic,
        Name: ResponseResult.Name,
        Role: ResponseResult.Role,
        Speciality: ResponseResult.Speciality,
        UserID: ResponseResult.UserID,
        UserPictureExists: ResponseResult.UserPictureExists,
        menuList: ResponseResult.menus,
        DivisionId: ResponseResult.DivisionId,
        ResourceEntity: ResponseResult.ResourceEntity,
        SubDivisionDesc: ResponseResult.SubDivisionDesc,
        SubDivisionId: ResponseResult.SubDivisionId,
        EmployeeID : ResponseResult.EmployeeNumber,
        token:
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJ3d3cuYWt1LmVkdSIsInN1YiI6Im1ubUBtbm0uY29tIiwiaXNzIjoiTW5NIn0.PRbF6RNmjYm7ai8jQNShBmyDLlZfIWv2Z6V2KosJgjE",
      };
      console.log("Login result ", ResponseResult);
      setSession(responseMod);
      setUserPicture(ResponseResult.UserPicture);
      setUserMenus(ResponseResult.menus);
      yield put(loginUserSuccess(responseMod));
    } else {
      console.log("login failed....");
      yield put(loginUserFailed(ResponseMessage));
    }
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(loginUserFailed(message));
    setSession(null);
  }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
  try {
    setSession(null);
    yield call(() => {
      history.push("/login");
    });
  } catch (error) {}
}

/**
 * Register the user
 */
function* register({ payload: { fullname, email, password } }) {
  const options = {
    body: JSON.stringify({ fullname, email, password }),
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const response = yield call(fetchJSON, "/users/register", options);
    yield put(registerUserSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(registerUserFailed(message));
  }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { username } }) {
  const options = {
    body: JSON.stringify({ username }),
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const response = yield call(fetchJSON, "/users/password-reset", options);
    yield put(forgetPasswordSuccess(response.message));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(forgetPasswordFailed(message));
  }
}

export function* watchLoginUser(): any {
  yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser(): any {
  yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser(): any {
  yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword(): any {
  yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

function* authSaga(): any {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgetPassword),
  ]);
}

export default authSaga;
